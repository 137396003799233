import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  standalone: true,
  imports: [MatCardModule],
  styleUrls: ['./error404.component.scss'],
})
export class Error404Component {
  constructor(private router: Router) {}

  navigateDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
