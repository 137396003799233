import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  @ViewChild('snav') sidenav!: MatSidenav;

  constructor(private authService: AuthService, public router: Router) {}

  ngAfterViewInit() {
    //By default open side nav on large screen
    this.sidenav?.open();
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('auth/login');
  }
}
