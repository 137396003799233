<main class="main">
    <div class="flex bg-blue-200 p-9 mb-4">
        <div class="flex-none w-14 ...">
            01
        </div>
        <div class="flex-initial w-64 ...">
            02
        </div>

        <!-- Material color -->
        <mat-icon color="primary">visibility</mat-icon>
        <!-- Tailwind color -->
        <mat-icon class="text-pink-800">visibility</mat-icon>

        <div class="flex-initial w-32 ...">
            03
        </div>
    </div>

    <div class="flex justify-center bg-teal-800 mb-4 text-white">
        <div>01</div>
        <div>02</div>
        <div>03</div>
    </div>

    <div class="flex flex-col bg-amber-400">
        <div>01</div>
        <div>02</div>
        <div>03</div>
    </div>

    <h1 class="text-xl font-bold underline">
        Hello world!
    </h1>

    <mat-slide-toggle class="my-5">Toggle me!</mat-slide-toggle>
    <br />


    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="my-5">
        <mat-button-toggle value="bold">Bold</mat-button-toggle>
        <mat-button-toggle value="italic">Italic</mat-button-toggle>
        <mat-button-toggle value="underline">Underline</mat-button-toggle>
    </mat-button-toggle-group>
    <br />

    <section class="my-5">
        <button mat-raised-button color="tertiary">Basic</button>
        <button mat-raised-button disabled>Disabled</button>
        <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
    </section>
    <br />

    <section class="my-5">
        <div class="example-label">Flat</div>
        <div class="example-button-row">
            <button mat-flat-button class="bg-sky-500 hover:bg-red-700">Basic</button>
            <button mat-flat-button disabled>Disabled</button>
            <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
        </div>
    </section>

    <button mat-raised-button color="primary" class="app-button my-3" (click)="showSuccess()">
        <mat-icon aria-hidden="false" aria-label="create">add</mat-icon>
        New Property
    </button>
    <br>

    <button mat-raised-button color="warn" class="app-button my-3" (click)="showSuccess()">
        <mat-icon color="warn">delete_forever</mat-icon>
        Delete
    </button>
    <br>

    <!-- <button class="bg-sky-500 hover:bg-sky-700 ...">
      Save changes
    </button>
  
    <button
      class="bg-violet-500 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 ...">
      Save changes
    </button> -->


    <p class="text-sm ...">The quick brown fox jumped over the lazy dog.</p>
    <p class="text-base ...">The quick brown fox ...</p>
    <p class="text-lg ...">The quick brown fox ...</p>
    <p class="text-xl ...">The quick brown fox ...</p>
    <p class="text-2xl ...">The quick brown fox jumped over the lazy dog.</p>
    <p class="uppercase ...">The quick brown fox ...</p>

</main>