import { Component } from '@angular/core';
import { ToasterService } from '../shared/services/toaster.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  title = 'leas-estate-ui';

  constructor(private toasterService: ToasterService) {}

  showSuccess() {
    this.toasterService.success('Something is cooking!', 'Lease Tech!');
  }
}
