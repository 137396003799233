import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get getUserData() {
    return JSON.parse(localStorage.getItem('User') || '{}');
  }

  logout() {
    localStorage.removeItem('User');
  }
}
