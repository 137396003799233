<section class="main-section">
    <!-- Top Nav -->
    <mat-toolbar>
        <button mat-icon-button (click)="snav.toggle()" class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon>menu</mat-icon>
        </button>
        <span>My App</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
            <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
            <mat-icon>share</mat-icon>
        </button>
        <button mat-raised-button color="warn" class="app-button my-3" (click)="logout()">
            <mat-icon color="warn">delete_forever</mat-icon>
            Logout
        </button>
    </mat-toolbar>

    <!-- Side nav -- Also is the parent container -->
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav class="side-nav" #snav [mode]="'side'">
          <mat-nav-list class="side-nav-list" style="padding-top:0; overflow-x: hidden;">
            <a mat-list-item routerLink="/" class="side-link text-gray-20"
              [class.active]="router.url==='/' || router.url.startsWith('/dashboard')">
              <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
              <span>Dashboard</span>
            </a>
    
            <a mat-list-item routerLink="/properties" class="side-link text-gray-20"
              [class.active]="router.url.startsWith('/properties')">
              <mat-icon aria-hidden="false" aria-label="properties icon">category</mat-icon>
              <span>Properties</span>
            </a>
          </mat-nav-list>
          <div id="side_nav_bottom_logo">
            <!-- <span class="text-gray-600 text-lg">LeasEstate Rent Management</span> -->
            <img src="/assets/images/Color logo - no background.svg" class="h-12" alt="Logo" />
          </div>
        </mat-sidenav>
    
        <mat-sidenav-content class="p-2" style="background:#F5F5F5">
          <main style="position: relative;">
            <router-outlet></router-outlet>
          </main>
          <div style="height: 10em;">
            <!-- My work is just to add space at the bottom -->
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>

</section>